@font-face {
    font-family: 'Apercu Pro';
    src: url('./fonts/ApercuPro-Bold.eot');
    src: local('Apercu Pro Bold'), local('ApercuPro-Bold'),
        url('./fonts/ApercuPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ApercuPro-Bold.woff2') format('woff2'),
        url('./fonts/ApercuPro-Bold.woff') format('woff'),
        url('./fonts/ApercuPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('./fonts/ApercuPro-Regular.eot');
    src: local('Apercu Pro Regular'), local('ApercuPro-Regular'),
        url('./fonts/ApercuPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/ApercuPro-Regular.woff2') format('woff2'),
        url('./fonts/ApercuPro-Regular.woff') format('woff'),
        url('./fonts/ApercuPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}